import React from 'react';
import { graphql } from 'gatsby';

import Container from '../components/global/container/Container';
import Carousel from '../components/common/carousel/Carousel';
import CarouselSlide from '../components/common/carousel/CarouselSlide';
import Hero from '../components/common/hero/Hero';
import Layout from '../components/layout';
import { NewsFeed } from '../components/sections/news-feed';
import { ServiceCategorySection } from '../components/common/service-category-section';
import { Testimonial } from '../components/common/testimonial';

const Home = ({ data, path }) => {
  const { wpPage } = data;

  // Get the first slide title for a hidden <h1> element
  const pageTitle =
    wpPage.carousel.slides.length > 0
      ? wpPage.carousel.slides[0].title
      : 'Welcome to Latimer Hinks';

  return (
    <Layout seo={wpPage.seo} path={path}>
      <h1
        style={{
          position: 'absolute',
          width: 1,
          height: 1,
          padding: 0,
          margin: -1,
          overflow: 'hidden',
          clip: 'rect(0, 0, 0, 0)',
          whiteSpace: 'nowrap',
          borderWidth: 0,
        }}
      >
        {pageTitle}
      </h1>

      <Hero isNotFlex>
        <div className="Hero__Carousel">
          <Carousel>
            {wpPage.carousel.slides.map(
              (slide, index) =>
                slide.image && (
                  <CarouselSlide
                    key={slide.title}
                    image={slide.image.sourceUrl}
                    category={slide.category}
                  >
                    <div className="CarouselSlide__Content">
                      <div className="CarouselSlide__ContentWrap">
                        <h2
                          className="main-title"
                          dangerouslySetInnerHTML={{ __html: slide.title }}
                          style={{
                            maxWidth: index === 0 ? 450 : 'inherit',
                          }}
                        />
                        {slide.sub_title && (
                          <h3
                            className="sub-title"
                            dangerouslySetInnerHTML={{
                              __html: slide.subTitle,
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </CarouselSlide>
                )
            )}
          </Carousel>
        </div>
      </Hero>

      {/* Service Categories */}
      <ServiceCategorySection />

      {/* Testimonials with Container */}
      <Container verticalGap={16}>
        <Testimonial />
        <NewsFeed />
      </Container>
    </Layout>
  );
};

export default Home;

export const pageQuery = graphql`
  {
    wpPage(slug: { eq: "homepage" }) {
      id
      title
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
      }
      carousel {
        slides {
          title
          subTitle
          category
          image {
            sourceUrl
          }
        }
      }
    }
  }
`;
