import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import config from '../../../helpers/styles/theme';
import Container from '../../global/container/Container';

const options = {
  maxWidth: {
    small: '600px',
    large: '100%',
  },
};

const StyledCarouselSlide = styled.div`
  border-bottom: 15px solid ${(props) => config.scheme[props.category]};
  color: rgba(${(props) => config.scheme.carouselTextRgba[props.category]});
  position: relative;
  min-height: 450px;
  width: 100%;

  @media screen and (min-width: 768px) {
    min-height: 550px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: .8;
    }
  }

  .CarouselSlide__Content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding-top: 80px;
    padding-right: 20px;
    z-index: 10;

    .CarouselSlide__ContentWrap {
      max-width: ${(props) => options.maxWidth[props.maxWidth]};
    }

    h1, .main-title {
      font-size: 28px;

      @media screen and (min-width: 768px) {
        font-size: 48px;
      }

      b {
        color: ${(props) => config.scheme.carouselTextColour[props.category]};
      }
    }

    h2, .sub-title {
      font-size: 20px;

      @media screen and (min-width: 768px) {
        font-size: 28px;
      }
    }

    h1, h2, h3 {
      font-weight: 200;
      letter-spacing: -.25px;
    }
  }
`;

const StyledImage = styled.div`
  background-image: url('${(props) => props.image}');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;

  @media screen and (min-width: 768px) {
    min-height: 550px;
  }
`;

const CarouselSlide = ({ children, image, category, maxWidth }) => (
  <StyledCarouselSlide category={category} maxWidth={maxWidth}>
    <Container>{children}</Container>
    <StyledImage image={image} className="CarouselSlide--Animated" />
  </StyledCarouselSlide>
);

CarouselSlide.defaultProps = {
  category: null,
  image: null,
  maxWidth: 'small',
};

CarouselSlide.propTypes = {
  children: PropTypes.node.isRequired,
  image: PropTypes.string,
  category: PropTypes.string,
  maxWidth: PropTypes.string,
};

export default CarouselSlide;
