import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import config from '../../../helpers/styles/theme';

const StyledHero = styled.div`
  position: relative;
  margin-top: -102px;

  ${props => props.article && `height: 500px;`};

  ${props =>
    props.article &&
    `
    @media print {
      height: 100px;
    }
  `}

  .slick-track {
    display: flex;

    .slick-slide {
      display: flex;
      height: auto;

      div:first-of-type {
        width: 100%;
      }
      width: 50%;
    }
  }

  .slick-dots.slick-dots {
    bottom: 25px;
  }

  &.HeroWrap--Lander {
    background-image: url('${props => props.backgroundImageUrl}');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    color: #ffffff;
    border-bottom: 15px solid ${props => config.scheme[props.category]};

    @media print {
      background: white;
    }

    h1 {
      font-size: 32px;

      @media screen and (min-width: 768px) {
        font-size: 40px;
      }
    }

    h1, h2 {
      font-weight: 200;
    }
  }
`;

const HeroWrap = ({
  isLandingPage,
  children,
  category,
  backgroundImageUrl,
  isNotFlex,
  article,
}) => (
  <StyledHero
    article={article}
    backgroundImageUrl={backgroundImageUrl}
    category={category}
    isNotFlex={isNotFlex}
    className={classnames({
      'HeroWrap--Lander': isLandingPage,
    })}
  >
    {children}
  </StyledHero>
);

HeroWrap.defaultProps = {
  isLandingPage: false,
  isNotFlex: false,
  backgroundImageUrl: null,
};

HeroWrap.propTypes = {
  children: PropTypes.node.isRequired,
  isLandingPage: PropTypes.bool,
  isNotFlex: PropTypes.bool,
  backgroundImageUrl: PropTypes.string,
  category: PropTypes.string.isRequired,
};

export default HeroWrap;
