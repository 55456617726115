/**
 * This is a reference point for the styled components theme provider.
 * Each category within the website has an allocated colour as per the brand,
 * this file contains a map that can be used to acquire the relevant colour
 * by category.
 */

export default {
  scheme: {
    generic: '#FFFFFF',
    'your-home': '#50407E',
    home: '#50407E',
    'farms-estates': '#587B26',
    farms: '#587B26',
    'business-commercial': '#00667D',
    commercial: '#00667D',
    'you-and-your-family': '#811167',
    family: '#811167',

    // RGBA Colours.
    // These are typically used on News Cards, refer to the homepage News Feed.
    rgba: {
      generic: '255, 255, 255, .9',
      'your-home': '80, 64, 126, .25',
      'farms-estates': '88, 123, 38, .25',
      'business-commercial': '0, 102, 125, .25',
      'you-and-your-family': '129, 17, 103, .25',
    },

    // Article Card RGBA Colours.
    articleRgba: {
      generic: '255, 255, 255, .75',
      'your-home': '80, 64, 126, .75',
      'farms-estates': '88, 123, 38, .75',
      'business-commercial': '0, 102, 125, .75',
      'you-and-your-family': '129, 17, 103, .75',
    },

    // Homepage Carousel Text Colour.
    carouselTextColour: {
      generic: '#ffffff',
      'your-home': '#B2A3DB',
      home: '#B2A3DB',
      'farms-estates': '#D09546',
      farms: '#D09546',
      'business-commercial': '#74C699',
      commercial: '#74C699',
      'you-and-your-family': '#A9A814',
      family: '#A9A814',
    },

    carouselTextRgba: {
      generic: '255, 255, 255, 1',
      'your-home': '178, 163, 219, .9',
      home: '178, 163, 219, .9',
      'farms-estates': '208, 149, 70, .9',
      farms: '208, 149, 70, .9',
      'business-commercial': '116, 198, 153, .9',
      commercial: '116, 198, 153, .9',
      'you-and-your-family': '169, 168, 20, .9',
      family: '169, 168, 20, .9',
    },

    // Testimonial Swatches
    testimonial: {
      'business-commercial': '#74C699',
      'farms-estates': '#D09546',
      'you-and-your-family': '#F58021',
      generic: '#F58021',
      'your-home': '#50407E',
    },

    // Knowledge Page Headers
    knowledgeHeader: {
      generic: '#00254a',
      'you-and-your-family': '#900067',
      'your-home': '#533d80',
      'farms-estates': '#4b7f1e',
      'business-commercial': '#00697e',
    },
  },
};
