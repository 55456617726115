import React, { Component } from 'react';
import PropTypes from 'prop-types';

import StyledHero from './StyledHero';

class Hero extends Component {
  render() {
    const {
      children,
      isCarousel,
      isLandingPage,
      backgroundImageUrl,
      category,
      isNotFlex,
      article,
    } = this.props;

    return (
      <StyledHero
        isCarousel={isCarousel}
        isLandingPage={isLandingPage}
        backgroundImageUrl={backgroundImageUrl}
        category={category}
        isNotFlex={isNotFlex}
        article={article}
      >
        {children}
      </StyledHero>
    );
  }
}

Hero.defaultProps = {
  isCarousel: false,
  isLandingPage: false,
  isNotFlex: false,
  backgroundImageUrl: null,
  category: 'generic',
  isStaffPage: false,
};

Hero.propTypes = {
  children: PropTypes.node.isRequired,
  isCarousel: PropTypes.bool,
  isLandingPage: PropTypes.bool,
  isStaffPage: PropTypes.bool,
  isNotFlex: PropTypes.bool,
  backgroundImageUrl: PropTypes.string,
  category: PropTypes.string,
};

export default Hero;
